* {
    padding: 0;
    margin: 0;
    border: 0;
    scrollbar-width: none;
}

*::-webkit-scrollbar {
    display: none;
}

body {
    background-color: #F1F1EF;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
