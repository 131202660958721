.imageSpread {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center; 
    align-items: center; 
    width: 100%;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: content-box;
    overflow: hidden;
    padding: 10px;
}

.imageContainer > img {
    max-width: 30vw;
    height: auto;
    z-index: 0;
    opacity: 0;
}

.imageColorBlock {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    background-color: #F1F1EF;
    transform: translateY(0%);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
}

.swipeUpAnim {
    animation: swipeUp 1.3s ease;
    opacity: 1;
}

@keyframes swipeUp {
    100% {
        transform: translateY(100%);
    }
}

.imageContainer:nth-child(odd) {
    grid-row: span 2;
}

.imageContainer:nth-last-child(-n + 1) {
   grid-column: span 2;
}

.imageContainer:nth-last-child(-n + 1) > img {
    max-width: 100%;
}

/* This media breakpoint targets screens with hover capabilities */
@media (hover:hover){
    .imageContainer {
        cursor: pointer;
        transform: translateY(0px);
        clip-path: circle(100%);
        transition: clip-path 500ms ease-in-out 150ms, transform 500ms ease-in-out 150ms;
    }
    
    .imageContainer:hover {
        transform: translateY(-0.9px);
        clip-path: circle(35%);
        transition: clip-path 500ms ease-in-out, transform 500ms ease-in-out;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .imageSpread {
        display: flex;
        flex-direction: column;
        margin-top: 25%; 
    }

    .imageContainer > img {
        max-width: 90vw !important;
        height: auto;
        transform: translateY(0px);
        clip-path: circle(100%);
        transition: clip-path 500ms ease-in-out, transform 500ms ease-in-out;
    }
}

/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 1500px) {
    .imageContainer > img {
        max-width: 30vw;
        height: auto;
    }
}
