#linkedin {
    padding-bottom: 5px;
}

.footer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 50px;
}

.contentContainer {
    display: 'flex'; 
    flex-direction: 'column';
}

.socialLogos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.socialLogos > a > svg  {
    margin-left: 3px;
    margin-right: 3px;
    width: 20px;
    height: 20px;
}

.llc {
    font-size: medium;
}

@media (hover:hover){
    .socialLogos:hover {
        fill: rgba(101, 101, 101, 0.402);
        transition: fill 500ms ease;
    }

    .socialLogos > a > svg:hover  {
        fill: #000000;
    }
}
