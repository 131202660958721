.postSpread {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: fit-content;
    margin: auto;
    overflow: hidden;
    padding: 20px;
}

#post {
    margin: 10px;
}

.postContainer, .carouselContainer {
    width: 300px;
    height: 290px;
    position: relative;
    box-sizing: border-box;
    opacity: 0;
    overflow: hidden; 
    transform: translateY(70%); 
    place-self: center;
}

.postContainer img, .carouselContainer img, .carouselContainer video {
    display: flex;
    width: 100%;
}

.nextCarouselPost {
    animation: nextCarouselPostAnim 400ms ease-in;
}

@keyframes nextCarouselPostAnim {
    0%{
        transform: translateX(110%);
    } 100% {
        transform: translateX(0%);
    }
}

.previousCarouselPost {
    animation: previousCarouselPostAnim 400ms ease-in;
}

@keyframes previousCarouselPostAnim {
    0%{
        transform: translateX(-110%);
    } 100% {
        transform: translateX(0%);
    }
}

.linkContainer {
    position: absolute;
    right: 0%;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #F1F1EF;
    opacity: 0.5;
    padding: 2px;
    margin: 5px;
}

.link {
    width: 100%;
    height: 100%;
}

.linkContainer:hover {
    opacity: 1;
}

.arrowContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translate(0%, -50%);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    background-color: #F1F1EF;
    opacity: 0.5;
    padding: 2px;
    margin: 5px;
    z-index: 100;
}

.arrowContainer:hover, #arrow:hover {
    opacity: 1;
}

#backArrow {
    left: 5%;
}

#forwardArrow {
    right: 5%;
}

#arrow {
    opacity: 0.5;
    cursor: pointer;
}

@media only screen and (max-width: 1500px) {
    .postSpread {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 970px) {
    .postSpread {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    .postSpread {
        grid-template-columns: repeat(1, 1fr);
        padding: 0px;
        padding-bottom: 20px;
    }
    .postContainer, .carouselContainer {
        width: 100vw;
        height: 100%;
    }
    #post {
        margin: 10px 0px 10px 0px;
    }
}
