.logoContainer {
    display: flex;
    flex-direction: row;   
    align-items: center;
}

.logo {
    width: 2em;
    height: 2em;
    flex-shrink: 0;
    fill: #F1F1EF;
}

.logoCopy {
    display: flex;
    flex-direction: row;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; 
    font-size: 1.3rem;
    padding-left: 0.5em;
    flex-shrink: 1;
    color: rgba(50, 50, 50, 50);
}

.logoCopy > span {
    padding-right: 5px;
}

#mobileLogo {
    display: none;
}

.mobileMenu {
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(188, 191, 163);
    z-index: 2;
}

.mobileMenu > div {
    margin: 15px;
    cursor: pointer;
    text-decoration: none;
    font-size: 25px;
    color: #F1F1EF;
}

/* This media breakpoint targets screens with hover capabilities */
@media (hover:hover){
    .mobileMenu div:hover {
        color: #F1F1EF;
    }

    .logo:hover {
        fill: white;
    }
}

.mobileMenuIntro {
    animation-name: mobileMenuIntroAnim;
    animation-duration: 1s;
}

@keyframes mobileMenuIntroAnim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mobileMenuExit {
    display: flex;
    justify-content: flex-end;
    animation-name: mobileMenuExitAnim;
    animation-duration: 1s;
}

@keyframes mobileMenuExitAnim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mobileMenuLink {
    animation: mobileMenuLinkAnim 500ms ease-in-out;
}

@keyframes mobileMenuLinkAnim {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(2.5px);
    }
}

.desktopMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 1.5em 0 1.5em;
    opacity: 0;
}

.menuItemContainer {
    display: flex;
    flex-direction: row;
}

.menuItems > div {
    text-decoration: none;
    font-size: 1.3em;
    color: rgb(182, 182, 182);
    position: relative; /* Ensure proper positioning of ::after pseudo-element */
    cursor: pointer;
    width: fit-content;
}

.menuItems > div::after {
    content: ""; /* Add content to the ::after pseudo-element */
    display: block; 
    width: 100%; /* Initial width to cover entire link */
    height: 2px; /* Height of the underline */
    background-color: rgb(188, 191, 163); 
    opacity: 0; /* Initial opacity */
    transform: scaleX(0); /* Initial scale transformation */
    transform-origin: center;
    transition: transform 500ms ease, opacity 500ms ease; 
}

.menuItems > div:hover::after, .desktopMenuItems > div:focus::after {
    transform: scaleX(1); /* Scale to full width on hover or focus */
    opacity: 1; /* Show the underline */
}

.menuItems > div:hover {
    color: rgb(188, 191, 163);
}

.desktopMenuIntro {
    animation: desktopMenuIntroAnim 500ms ease-in-out;
    opacity: 1;
}

@keyframes desktopMenuIntroAnim {
    0% {
        opacity: 0;
        transform: translateY(5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.desktopMenuLink {
    animation: desktopMenuLinkAnim 500ms ease-in-out forwards;
}

@keyframes desktopMenuLinkAnim {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(5px);
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .logo { 
        width: 3em;
        height: 3em;
        margin: 15px;
    }
    .desktopMenu {
        display: none;
    }
    #mobileLogo {
        display: block;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Extra large devices (large laptops and desktops, 1000px and up) */
@media only screen and (min-width: 1000px) {
    
}

/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 1500px) {}
