.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;  
    background-color: #F1F1EF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.lightboxContainer {
    display: flex;
    align-items: center;
}

.innerContainer > img {
    max-width: 80vw;
    max-height: 80vh;
    padding-top: 20px;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#nextButton {
    position: absolute;
    right: 15px;
}

.forwardTransitionOut {
    animation: forwardTransitionOutAnim 700ms ease-in-out;
}

@keyframes forwardTransitionOutAnim {
    0% {
        transform: translateX(0px);

    } 100% {
        transform: translateX(200px);
        opacity: 0;
    }
}

.nextImage {
    animation: forwardAnim 700ms ease-in-out;
}

@keyframes forwardAnim {
    0% {
        transform: translateX(-200px);
    } 100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

#prevButton {
    position: absolute;
    left: 15px;
}

.previousImage {
    animation: backAnim 700ms ease-in-out;
}

@keyframes backAnim {
    0% {
        transform: translateX(200px);
        opacity: 0;
    } 
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.backTransitionOut {
    animation: backTransitionOutAnim 700ms ease-in-out;
}

@keyframes backTransitionOutAnim {
    0% {
        transform: translateX(0px);

    } 100% {
        transform: translateX(-200px);
        opacity: 0;
    }
}

.lightboxButton {
    margin: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
}

@media (hover:hover){
    .lightboxButton:hover {
        color: rgb(188, 191, 163);
    }
}

#closeButton {
    position: absolute;
    font-size: 1.5rem;
    top: 10px;
}

.lightboxEntrance {
    animation: lightboxIntroAnim 1s ease forwards;
}

@keyframes lightboxIntroAnim {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
    }
}

.lightboxExit{
    animation: lightboxExitAnim 1s linear;
}

@keyframes lightboxExitAnim {
    from {
        transform: translateY(0px);
    }
    to {
        opacity: 0;
        transform: translateY(-25px);
    }
}

.loading {
    position: fixed;
    z-index: 999;
    height: 1.5em;
    width: 1.5em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
  
/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
  
/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
  
.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 13px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.704) 1.5em 0 0 0, rgba(0, 0, 0, 0.704) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.704) 0 1.5em 0 0, rgba(0, 0, 0, 0.704) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.704) -1.5em 0 0 0, rgba(0, 0, 0, 0.704) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.704) 0 -1.5em 0 0, rgba(0, 0, 0, 0.704) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.704) 1.5em 0 0 0, rgba(0, 0, 0, 0.704) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.704) 0 1.5em 0 0, rgba(0, 0, 0, 0.704) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.704) -1.5em 0 0 0, rgba(0, 0, 0, 0.704) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.704) 0 -1.5em 0 0, rgba(0, 0, 0, 0.704) 1.1em -1.1em 0 0;
}
  
/* Animation */
  
@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #closeButton {
        margin: 5px;
        top: 10px;
    }

    #nextButton {
        margin-right: -10px;
        right: 5px;
    }

    #prevButton {
        margin-left: -10px;
        left: 5px;
    }
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {} */
