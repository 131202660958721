.contactContainer {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    margin-top: 10%;
}

.contactBody {
    display: block;
    margin: auto;
    width: 90vw;
    margin-bottom: 5%;
}

.contactIntro {
    font-size: 1.3em;
    padding-bottom: 20px;
    text-align:justify;
    text-align: center;
    flex-direction: column;
    display: flex;
    margin: 0 auto;
    width: 80%; 
}

.contactIntro > a {
    padding: 10px;
    color: rgb(138, 142, 105);
    text-decoration: none;
}

.line {
    border: rgba(128, 128, 128, 0.578) 0.5px;
    border-style: solid;
    margin: 20px;
}

.email {
    display: block;
    margin: auto;
    width: fit-content;
}

.formContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.nameContainer {
    display: flex;
    flex-direction: row;
}

.nameContainer > .formContainer {
    width: 100%;
}

.formContainer {
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
}

.formContainer > input {
    font-size: inherit;
    font-family: inherit;
    box-sizing: border-box;
    padding: 10px;
    margin: 10px 0px 0px 0px;
    border: 1px solid rgba(128, 128, 128, 0.578);
}

.formContainer > textarea {
    font-family: inherit;
    font-size: inherit;
    box-sizing: border-box;
    width: inherit;
    height: 150px;
    padding: 10px;
    margin: 10px 0px 0px 0px;
    border: 1px solid rgba(128, 128, 128, 0.578);
}

.submitButton {
    cursor: pointer;
    display: block;
    margin: auto;
    padding: 10px;
    margin: 10px;
    background-color: rgb(188, 191, 163); 
    color: #F1F1EF;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 300;
}

.submitButton:hover {
    background-color: rgba(188, 191, 163, 0.821); 
}

@media only screen and (max-width: 600px) {
    .contactBody {
        width: 100%;
    }

    .nameContainer > .formContainer {
        display: flex;
        width: 50%;
    }
}

@media only screen and (min-width: 900px)  {
    .contactBody {
        width: 1000px;
    }
}   
